import { navigateTo, persistedState } from "#imports";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import useTableUrlHelpers from "~/composables/useTableUrlHelpers";
import type { FrontendRequestObject } from "~/src/models/utils/Api.model";

import { QUERY_KEYS } from "~/utils/queryKeys";

export const DEFAULT_REQUEST: FrontendRequestObject = {
  page: 1,
  pageSize: 20,
  filters: [],
  meta: {
    assignment: "all",
  },
};

export const useCaseTableStore = defineStore(
  "caseTable",
  () => {
    const { getFromURL, getQueryString } = useTableUrlHelpers(
      QUERY_KEYS.Cases.list,
      DEFAULT_REQUEST
    );

    const request = ref<FrontendRequestObject>(getFromURL());
    const queryKey = computed(() => [QUERY_KEYS.Cases.list, request] as const);

    const goToCaseList = () => {
      const url = getQueryString(request.value);
      navigateTo({ path: "/cases", query: { [QUERY_KEYS.Cases.list]: url } });
    };

    return {
      request,
      queryKey,
      goToCaseList,
    };
  },
  {
    persist: {
      storage: persistedState.sessionStorage,
      paths: ["request"],
    },
  }
);
